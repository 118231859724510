<template>
  <div class="activity-list">
    <div class="begin mr-6">
      <div
        class="header rounded-lg p-4"
        :style="{ backgroundColor: activitiesLists.background }"
      >
        <div class="icon">
          <svg-icon
            :name="activitiesLists.icon"
            original
            class="w-6 h-6"
          />
        </div><br>
        <div class="title text-black text-sm">{{ activitiesLists.title }}</div><br>
        <div class="number text-black font-semibold text-2xl">{{ activitiesLists.number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'activity-list',
  props: {
    activitiesLists: {
      type: Object,
      default: function () {
        return {
          background: '#CCF2F6',
          icon: 'ic_chat',
          title: this.$t('privateDiscussion'),
          number: '098'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .header {
    border-radius: .75rem;
  }
</style>
