<template>
  <div class="users-details">
    <popup-compte>
      <div class="begin bg-white p-8 pb-24 h-screen overflow-y-scroll">
        <div class="header flex items-center">
          <div class="icon mr-6 cursor-pointer">
            <svg-icon
              name="ic_go-back"
              original
              class="w-8"
              @click="goBack"
            />
          </div>
          <div class="back text-black font-medium text-lg">
            {{ $t('usersInformation') }}
          </div>
        </div><br>
        <div class="information mt-4">
          <div class="names-info flex items-center">
            <div class="picture mr-4 rounded-full w-12 h-12">
              <svg-icon
                v-if="userDetails.pictureId === ''"
                name="ic_user"
                original
                class="w-12 h-12 rounded-full"
              />
              <div v-else v-bind:style="{ 'background-image': 'url(' + picture + ')' }" class="bg-no-repeat bg-cover w-12 h-12 rounded-full"></div>
            </div>
            <div class="infos text-black">
              <div class="name font-medium">
                <div v-if="userDetails.firstName === ''">{{ userDetails.pseudo }}</div>
                <div v-else>{{ userDetails.firstName + ' ' + userDetails.lastName }}</div>
              </div>
              <div class="age text-sm mt-2">
                <span v-if="userDetails.profession === ''"></span>
                <span v-else>{{ userDetails.profession + ', ' }}</span>
                <span v-if="old !== null"> {{ old + ' ans' }}</span>
                <span v-if="old === null"></span>
              </div>
            </div>
          </div><br>
          <div class="contacts-infos flex items-center">
            <div class="phone flex items-center mr-8">
              <div class="icon mr-3">
                <svg-icon
                  name="ic_phone"
                  original
                  color="gray"
                  class="w-3 h-3"
                />
              </div>
              <div class="num text-black text-sm">
                <div v-if="userDetails.phone === null">-</div>
                <div v-else>{{ userDetails.phone }}</div>
              </div>
            </div>
            <div class="phone flex items-center ml-8">
              <div class="icon mr-3">
                <svg-icon
                  name="ic_location"
                  original
                  color="gray"
                  class="w-3 h-3"
                />
              </div>
              <div class="num text-black text-sm">
                <div v-if="userDetails.location === ''">
                  <div v-if="userDetails.town === ''">-</div>
                  <div v-else>{{ userDetails.town }}</div>
                </div>
                <div v-else>{{ userDetails.location }}</div>
              </div>
            </div>
          </div>
        </div><br>
        <div class="activities mt-4">
          <div class="title text-black font-semibold">{{ $t('activities') }}</div><br>
          <div class="activities-infos flex">
            <activity-list
              v-for="(item, i) in activities"
              :key="i"
              :activities-lists="item"
              class="w-1/3"
            />
          </div>
        </div><br>
        <div class="infos-lists mt-4">
<!--          <infos-list-->
<!--            v-for="(item, i) in messages"-->
<!--            :key="i"-->
<!--            :infos-lists="item"-->
<!--          />-->
        </div>
      </div>
    </popup-compte>
  </div>
</template>

<script>
import PopupCompte from '../add/popup-compte'
import ActivityList from './activity-list'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
// import InfosList from './infos-list'
export default {
  name: 'users-details',
  components: {
    // InfosList,
    ActivityList, PopupCompte
  },
  props: {
    userDetails: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      old: null,
      activities: [
        {
          background: '#CCF2F6',
          icon: 'ic_chat',
          title: this.$t('privateDiscussion'),
          number: '00'
        },
        {
          background: '#FFE7E9',
          icon: 'ic_forum',
          title: this.$t('forum'),
          number: '00'
        },
        {
          background: '#CFDFFF',
          icon: 'ic_quiz',
          title: this.$t('quiz'),
          number: '00'
        }
      ],
      messages: [
        {
          date: '20/12/2020',
          message: 'Nouvelle discussion privé avec Roland KETE'
        },
        {
          date: '20/12/2020',
          message: 'A rejoins le forum "Vie relationnelle et affective chez les jeunes adolescents"'
        },
        {
          date: '20/12/2020',
          message: '15 points gagné dans le quizz "Droit en santé sexuelle"'
        }
      ],
      stats: null,
      picture: '',
      imgBase: 'data:image/jpeg;base64,'
    }
  },
  mounted () {
    if (this.userDetails.birthday !== '') {
      this.old = new Date().getFullYear() - this.stringToDate(this.userDetails.birthday, 'dd/MM/yyyy', '/').getFullYear()
    }
  },
  created () {
    console.log(this.userDetails)
    this.getUserPicture()
    this.getAllStat()
  },
  methods: {
    getUserPicture () {
      if (this.userDetails.pictureId !== '') {
        http.get(apiroutes.baseURL + apiroutes.getFileById + this.userDetails.pictureId, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        })
          .then(response => {
            console.log(response)
            this.picture = this.imgBase + response.file
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    stringToDate (_date, _format, _delimiter) {
      const formatLowerCase = _format.toLowerCase()
      const formatItems = formatLowerCase.split(_delimiter)
      const dateItems = _date.split(_delimiter)
      const monthIndex = formatItems.indexOf('mm')
      const dayIndex = formatItems.indexOf('dd')
      const yearIndex = formatItems.indexOf('yyyy')
      let month = parseInt(dateItems[monthIndex])
      month -= 1
      return new Date(dateItems[yearIndex], month, dateItems[dayIndex])
    },
    goBack () {
      this.$emit('closeUsersDetail', false)
    },
    getAllStat () {
      http.post(apiroutes.baseURL + apiroutes.userStat, {
        userId: this.userDetails.userId
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      })
        .then(response => {
          // console.log(response)
          this.stats = response
          this.activities[0].number = response.chats
          this.activities[1].number = response.forums
          this.activities[2].number = response.quiz
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
</style>
