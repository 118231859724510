<template>
  <div class="users">
    <div class="begin">
      <div class="header">
        <div class="flex items-center justify-end">
          <div class="flex-one current-month ">
            <div class="search-bloc flex items-center justify-between">
              <div class="recherche-name">
                <div class="relative recherche justify-center items-center pl-8 pr-4 py-4 bg-white shadow rounded">
                  <div class="icon-search absolute inset-y-0 left-0 flex items-center px-4">
                    <svg-icon
                      name="ic_search"
                      original
                      class="icon w-4 h-4"
                    />
                  </div>
                  <div class="search-input ml-4">
                    <label>
                      <input
                        v-model="search"
                        type="text"
                        :placeholder="$t('searchName')"
                        class="recherche-input"
                      >
                    </label>
                  </div>
                </div>
              </div>
              <div class="select-pays">
                <user-filter
                  :status-list="professionList"
                  @selectedValue="selectedValueProfession"
                />
<!--                <select>
                  <option v-for="(item, key) in professionList" :key="key">{{ item.name }}</option>
                </select>-->
              </div>
              <div class="select-pays">
                <town-select
                  :status-list="statusList"
                  @selectedValue="selectedValue"
                />
              </div>
            </div>
          </div>
        </div><br>
      </div>
      <div class="user-list bg-white rounded font-semibold shadow p-6 flex items-center">
        <div class="flex-one item-color w-2/5">{{ $t('completeName') }}</div>
        <div class="flex-one item-color w-1/5">{{ $t('phone') }}</div>
        <div class="flex-one item-color w-1/3">{{ $t('email') }}</div>
        <div class="flex-one item-color w-1/12">{{ $t('sex') }}</div>
        <div class="flex-one item-color w-1/3">{{ $t('profession') }}</div>
        <div class="flex-one item-color w-1/4">{{ $t('town') }}</div>
        <div class="flex-one item-color w-1/4">{{ $t('pseudo') }}</div>
        <div class="flex-one item-color w-1/4">{{ $t('creationDate') }}</div>
        <div class="flex-one item-color w-1/12"></div>
      </div>
      <div class="loading" :class="{ 'not_empty': isLoading === false }">
        <loading
          :active="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="fullPage"
          loader="dots"
          color="#0B5382"
          background-color="transparent"
        />
      </div>
      <div v-if="nothing && !isLoading" class="no-student text-center text-lg py-32">
        {{ $t('noUserAdd') }}
      </div>
      <div class="list-user cursor-pointer" v-if="users.length > 0 && !isLoading">
        <users-list
          v-for="(item, i) in sortedArrayUser"
          :key="i"
          :user-list="item"
          @click.native="showUserDetail(item)"
        />

        <pagination-base
          :currentPage="currentPage"
          :totalPages="totalPages"
          :visiblePages="visiblePages"
          @page-change="handlePageChange"
        />
      </div><br>
    </div>
    <users-details
      v-if="userDetails"
      @closeUsersDetail="closeUsersDetail"
      :userDetails="selectedItem"
    />
  </div>
</template>

<script>
import TownSelect from '../../components/helper/town-select'
import UsersList from '../../components/helper/users/users-list'
import UsersDetails from '../../components/helper/users/users-details'
import http from '../../plugins/http'
import apiRoutes from '../../router/api-routes'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import UserFilter from '@/components/helper/users/user-filter'
import towns from '@/components/helper/add/allTowns'
import PaginationBase from '@/components/helper/add/pagination-base.vue'
export default {
  name: 'index',
  components: { PaginationBase, UserFilter, Loading, UsersDetails, UsersList, TownSelect },
  data () {
    return {
      userDetails: false,
      search: '',
      statusList: [...towns],
      trieOption: 'all',
      users: [],
      selectedItem: null,
      isLoading: true,
      fullPage: false,
      nothing: false,
      currentPage: 1,
      totalPages: 0,
      visiblePages: 0,
      limit: 10,
      selectedProfession: '',
      professionList: [
        {
          name: 'Tout',
          value: 'Tout'
        },
        {
          name: 'Animateur',
          value: 'Animateur'
        },
        {
          name: 'Autres',
          value: 'Autres'
        },
        {
          name: 'Commerçant(e)',
          value: 'Commerçant(e)'
        },
        {
          name: 'Elève',
          value: 'Elève'
        },
        {
          name: 'Etudiant(e)',
          value: 'Etudiant(e)'
        },
        {
          name: 'Infirmier(e)',
          value: 'Infirmier(e)'
        },
        {
          name: 'Médécin généraliste',
          value: 'Médécin généraliste'
        },
        {
          name: 'Médécin Spécialiste',
          value: 'Médécin Spécialiste'
        },
        {
          name: 'Sage-femme',
          value: 'Sage-femme'
        }
      ]
    }
  },
  computed: {
    /* sortedArrayUser: function () {
      let sorted = []
      for (let i = 0; i < this.users.length; i++) {
        if (this.trieOption === 'all' || this.trieOption === 'TOUT') {
          sorted.push(this.users[i])
        } else {
          if (this.users[i].town.toLowerCase() === this.trieOption.toLowerCase()) {
            sorted.push(this.users[i])
          } else if (this.users[i].location.toLowerCase() === this.trieOption.toLowerCase()) {
            sorted.push(this.users[i])
          }
        }
        if (this.selectedProfession === 'Tout') {
          sorted.push(this.users[i])
        } else {
          if (this.users[i].profession.toLowerCase() === this.selectedProfession.toLowerCase()) {
            sorted.push(this.users[i])
          }
        }
        /!* if (this.selectedProfession !== '') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.search = this.selectedProfession
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.search = ''
        } *!/
      }
      if (this.search !== '') {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if ((item.firstName !== null && (item.firstName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() + ' ' + item.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.pseudo !== null && (item.pseudo.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.phone !== null && (item.phone.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.profession !== null && (item.profession.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.email !== null && (item.email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
            // console.log(item.mobile)
            return item
          }
        })
      }
      return sorted
    } */
    sortedArrayUser: function () {
      const uniqueSet = new Set()

      // Filtrer en fonction de trieOption
      if (!this.trieOption || this.trieOption === 'all' || this.trieOption === 'TOUT') {
        for (let i = 0; i < this.users.length; i++) {
          uniqueSet.add(this.users[i])
        }
      } else {
        for (let i = 0; i < this.users.length; i++) {
          if (
            this.users[i].town.toLowerCase() === this.trieOption.toLowerCase() ||
            this.users[i].location.toLowerCase() === this.trieOption.toLowerCase()
          ) {
            uniqueSet.add(this.users[i])
          }
        }
      }

      // Filtrer en fonction de selectedProfession
      let professionSet = new Set()
      if (!this.selectedProfession || this.selectedProfession === 'Tout') {
        professionSet = new Set([...uniqueSet])
      } else {
        for (const item of uniqueSet) {
          if (item.profession.toLowerCase() === this.selectedProfession.toLowerCase()) {
            professionSet.add(item)
          }
        }
      }

      // Filtrage supplémentaire en fonction de la recherche
      let searchSet = new Set()
      if (!this.search) {
        searchSet = new Set([...professionSet])
      } else {
        const authorNameSearchString = this.search.toLowerCase()
        searchSet = new Set([...professionSet].filter((item) => {
          return (
            (item.firstName !== null &&
              (item.firstName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() +
                ' ' +
                item.lastName
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase()).indexOf(
                authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
              ) !== -1) ||
            (item.pseudo !== null &&
              item.pseudo
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase().indexOf(
                  authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                ) !== -1) ||
            (item.phone !== null &&
              item.phone
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase().indexOf(
                  authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                ) !== -1) ||
            (item.profession !== null &&
              item.profession
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase().indexOf(
                  authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                ) !== -1) ||
            (item.email !== null &&
              item.email
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase().indexOf(
                  authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                ) !== -1)
          )
        }))
      }

      // Convertir l'ensemble en tableau pour l'affichage
      const sorted = Array.from(searchSet)
      // console.log(sorted)
      return sorted
    }
  },
  created () {
    this.getAllUser(this.currentPage, this.limit)
    // this.getListTown()
  },
  mounted () {
    this.statusList.sort(function (x, y) {
      const a = x.name.toUpperCase()
      const b = y.name.toUpperCase()
      return a === b ? 0 : a > b ? 1 : -1
    })
    const all = { name: 'TOUT' }
    this.statusList.unshift(all)
    // console.log(this.statusList)
  },
  methods: {
    handlePageChange (newPage) {
      // Mettez à jour la page actuelle ici (ex: effectuez une requête pour charger la nouvelle page de données)
      this.currentPage = newPage
      this.getAllUser(newPage, this.limit)
    },
    getListTown () { // GET BENIN ALL TOWN LIST
      http.get('https://bj-decoupage-territorial.herokuapp.com/api/v1/towns')
        .then(response => {
          // console.log(response.towns)
          this.statusList = response.towns
        }).catch(error => {
          console.log(error)
        })
    },
    showUserDetail (item) {
      this.userDetails = true
      this.selectedItem = item
    },
    closeUsersDetail (val) {
      this.userDetails = val
    },
    selectedValueProfession (answer) {
      this.selectedProfession = answer
      // console.log(answer)
    },
    selectedValue (answer) {
      this.trieOption = answer
    },
    getAllUser (page, limit) { // GET ALL USER
      http.post(apiRoutes.baseURL + apiRoutes.getAllUser, {
        type: 'USER',
        page: page,
        limit: limit
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      })
        .then(response => {
          console.log(response)
          this.users = response.rows
          this.currentPage = page
          this.totalPages = Math.ceil(response.count / limit)
          // console.log(this.totalPages);
          const visiblePage = 1
          this.visiblePages = Math.min(visiblePage, this.totalPages)
          /* for (const i in response) {
            if (response[i].pseudo === 'Godblessing') {
              console.log(response[i])
            }
          } */
          if (this.users.length === 0) {
            this.isLoading = false
            this.nothing = true
          } else {
            this.isLoading = false
            this.nothing = false
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .users {
    padding: 2rem 4rem 4rem 1.5rem;
  }
  .user-list {
    color: $light_gray;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
